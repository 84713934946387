import { Datagrid, ReferenceManyField, TextField } from "react-admin"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"

export const OrganizationShowVnsDevicesLegacy = () => (
  <ReferenceManyField reference="patientProfile" target="organizationId" label={false} perPage={ALL_RECORDS_LIMIT}>
    <Total />
    <Datagrid bulkActionButtons={false}>
      <TextField source="currentIPG.serial" label="VNS Device ID" />
      <TextField source="currentIPG.model" label="Model" />
    </Datagrid>
    <Total />
  </ReferenceManyField>
)
