import { Datagrid, DateField, ReferenceManyField, TextField } from "react-admin"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"

export const OrganizationShowVnsDevices = () => {
  return (
    <ReferenceManyField reference="vnsDevice" target="organizationId" label={false} perPage={ALL_RECORDS_LIMIT}>
      <Total />
      <Datagrid bulkActionButtons={false}>
        <TextField source="generatorSerialNumber" label="VNS Device ID" />
        <TextField source="patientName" label="Patient Name" />
        <DateField source="patientDob" label="Patient DOB" />
        <DateField source="implantedDate" label="Implant Date" />
        <TextField source="generatorModel" label="Model" />
        <TextField source="blc" label="BLC (years)" />
        <DateField source="lastKnownReading" label="Last Known Reading" />
        <TextField label="Normal Output Current (mA)" source="normalOutputCurrent" />
        <TextField label="Normal Pulse Width (μSec)" source="normalPulseWidth" />
      </Datagrid>
      <Total />
    </ReferenceManyField>
  )
}
