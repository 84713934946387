import { Typography } from "@mui/material"
import styled from "styled-components"
import { environment } from "../services/environment"

const VersionBox = styled(Typography)`
  z-index: 100;
  position: fixed;
  bottom: 10px;
  left: 8px;
  padding: 4px 6px;
  color: white;
  background-color: ${(p) => p.theme.palette.secondary.main};
  border-radius: 8px;
  border-style: solid;
  border-color: ${(p) => p.theme.palette.secondary.dark};
  border-width: 1px;
`

const EnvContainer = styled.span`
  text-transform: capitalize;
`
const Version = () => {
  const { version, env } = environment

  return (
    <VersionBox variant="caption">
      <EnvContainer>{env}</EnvContainer>: v{version}
    </VersionBox>
  )
}
export default Version
