import { Grid } from "@mui/material"
import { Create, CreateProps, SelectArrayInput, SimpleForm, TextInput } from "react-admin"
import { CreateOrganizationInput, CreateOrganizationMutationVariables } from "../../API"
import { organizations } from "./helpers"
import { TerritoryInput } from "./TerritoryInput"

type FormProps = Pick<CreateOrganizationInput, "name" | "organizationTypes" | "territory">

export const OrganizationCreate = (props: CreateProps) => {
  const transform = (data: FormProps): CreateOrganizationMutationVariables => ({ input: data })

  return (
    <Create {...props} title="Create an Organization" redirect="show" transform={transform}>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput source="name" fullWidth autoFocus required />
            <TerritoryInput />
            <SelectArrayInput source="organizationTypes" choices={organizations} fullWidth required />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
