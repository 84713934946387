import { SelectInput } from "react-admin"
import { useWatch } from "react-hook-form"
import { OrganizationType } from "../../API"
import { territories } from "./helpers"

export const TerritoryInput = () => {
  const organizationTypes = useWatch({ name: "organizationTypes" })
  return (
    <SelectInput
      source="territory"
      choices={territories}
      fullWidth
      required={organizationTypes && organizationTypes.indexOf(OrganizationType.VNS_PORTAL) > -1}
    />
  )
}
