import { useFlags as useLdFlags } from "launchdarkly-react-client-sdk"
interface Flags {
  versionInformation: boolean
  tabletManagement: boolean
  organizationsPatientsManagement: boolean
  hcpInvitationRole: "Admin" | "Owner" | "Mangaer" | ""
  maintenanceMode: boolean
  organizationLogo: boolean
  vnsDevicesLegacy: boolean
  vnsDevices: boolean
}
export const useFlags = () => useLdFlags() as Flags
