import { useLDClient } from "launchdarkly-react-client-sdk"
import { useMemo } from "react"
import { useAuthState, useGetIdentity } from "react-admin"

export const IdentityProvider = () => {
  const { data, isLoading } = useGetIdentity()
  const { authenticated } = useAuthState()
  const ldClinet = useLDClient()
  useMemo(() => {
    if (isLoading || !ldClinet) {
      return
    }

    if (authenticated && data) {
      const { id, email } = data
      ldClinet.identify({ key: id.toString(), email })
    } else {
      ldClinet.identify({ anonymous: true })
    }
  }, [authenticated, data, isLoading, ldClinet])
  return null
}
