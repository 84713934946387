import { Loading } from "react-admin"
import styled from "styled-components"

export const LoginRedirect = () => (
  <Container>
    <Loading loadingPrimary="Redirecting to Okta" loadingSecondary="Please wait..." />
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
