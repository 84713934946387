import { Add } from "@mui/icons-material"
import { useState } from "react"
import { CreateButton, Show, ShowProps, TabbedShowLayout, useRecordContext } from "react-admin"
import { useLocation } from "react-router"
import { BreadCrumbActionToolbar } from "../../components/BreadcrumbActionToolbar"
import { useFlags } from "../../hooks/useFlags"
import { environment } from "../../services/environment"
import { OrganizationShowHcps } from "./OrganizationShow/OrganizationShowHcps"
import { OrganizationShowPatients } from "./OrganizationShow/OrganizationShowPatients"
import { OrganizationShowTablets } from "./OrganizationShow/OrganizationShowTablets"
import { OrganizationShowVnsDevices } from "./OrganizationShow/OrganizationShowVnsDevices"
import { OrganizationShowVnsDevicesLegacy } from "./OrganizationShow/OrganizationShowVnsDevicesLegacy"

type ActiveTab = "hcps" | "tablets" | "vns-devices" | "vns-devices-legacy" | "patients"

export const OrganizationShow = (props: ShowProps) => {
  const location = useLocation()
  const { tabletManagement, organizationsPatientsManagement, vnsDevices, vnsDevicesLegacy } = useFlags()

  function getInitialTab(pathname: string) {
    const path = pathname.split("/").at(-1)
    switch (path) {
      case "hcps":
        return "hcps"
      case "tablets":
        return "tablets"
      case "patients":
        return "patients"
      case "vns-devices":
        return "vns-devices"
      default:
        return "hcps"
    }
  }

  const [activeTab, setActiveTab] = useState<ActiveTab>(getInitialTab(location.pathname))
  return (
    <Show
      {...props}
      queryOptions={{ meta: { primaryKey: "organizationId" } }}
      title="Organization"
      actions={<BreadCrumbActionToolbar labelSource="name" rightView={<DynamicCreateButton activeTab={activeTab} />} />}
    >
      <TabbedShowLayout value={0}>
        <TabbedShowLayout.Tab
          label="HCPS"
          onClick={() => {
            setActiveTab("hcps")
          }}
        >
          <OrganizationShowHcps />
        </TabbedShowLayout.Tab>
        {tabletManagement && (
          <TabbedShowLayout.Tab
            label="TABLETS"
            onClick={() => {
              setActiveTab("tablets")
            }}
            path="tablets"
          >
            <OrganizationShowTablets />
          </TabbedShowLayout.Tab>
        )}
        {!environment.isPortal && organizationsPatientsManagement && (
          <TabbedShowLayout.Tab
            label="EPSY PATIENTS"
            path="patients"
            onClick={() => {
              setActiveTab("patients")
            }}
          >
            <OrganizationShowPatients />
          </TabbedShowLayout.Tab>
        )}
        {tabletManagement && vnsDevicesLegacy && (
          <TabbedShowLayout.Tab
            label={vnsDevices ? "VNS DEVICES (Legacy)" : "VNS DEVICES"}
            path="vns-devices-legacy"
            onClick={() => {
              setActiveTab("vns-devices-legacy")
            }}
          >
            <OrganizationShowVnsDevicesLegacy />
          </TabbedShowLayout.Tab>
        )}
        {tabletManagement && vnsDevices && (
          <TabbedShowLayout.Tab
            label="VNS DEVICES"
            path="vns-devices"
            onClick={() => {
              setActiveTab("vns-devices")
            }}
          >
            <OrganizationShowVnsDevices />
          </TabbedShowLayout.Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}

interface DynamicCreateButtonProps {
  activeTab: ActiveTab
}

const DynamicCreateButton = ({ activeTab }: DynamicCreateButtonProps) => {
  const record = useRecordContext()
  return (
    <>
      {activeTab === "hcps" && (
        <CreateButton
          icon={<Add />}
          label="Invite new HCP"
          to={{
            pathname: `/invitation/create`,
            search: `organizationId=${record?.id}`
          }}
        />
      )}
      {activeTab === "tablets" && (
        <CreateButton
          icon={<Add />}
          label="Add new tablet"
          to={{
            pathname: "/tablet/create",
            search: `organizationId=${record?.id}`
          }}
        />
      )}
    </>
  )
}
