import {
  AdminContext,
  AdminUI,
  CustomRoutes,
  defaultI18nProvider,
  Loading,
  localStorageStore,
  Resource
} from "react-admin"
import { Route } from "react-router-dom"
import packageJson from "../package.json"
import gql from "./graphql/gql"
import { ReactComponent as OrganizationIcon } from "./images/icons/organizations.svg"
import { buildAuthProvider, buildDataProvider } from "./providers"
import { DataProviderOptions } from "./providers/DataProvider"
import { IdentityProvider } from "./providers/IdentityProvider"
import { epsyTheme } from "./theme"
import { LoginRedirect } from "./views/auth/LoginRedirect"
import { Logout } from "./views/auth/Logout"
import { HcpCreate } from "./views/hcps/HcpCreate"
import { HcpEdit } from "./views/hcps/HcpEdit"
import { OrganizationCreate } from "./views/organization/OrganizationCreate"
import { OrganizationEdit } from "./views/organization/OrganizationEdit"
import { OrganizationList } from "./views/organization/OrganizationList"
import { OrganizationShow } from "./views/organization/OrganizationShow"
import { TabletCreate } from "./views/tablet/TabletCreate"
import { TabletEdit } from "./views/tablet/TabletEdit"

const store = localStorageStore()
const dataProviderOptions: DataProviderOptions = {
  queryNameManyMaps: [
    {
      resource: "hcp",
      operation: "list",
      target: "organizationId",
      query: "listHcp"
    }
  ]
}

export const Admin = () => (
  <AdminContext
    dataProvider={buildDataProvider(gql, dataProviderOptions)}
    authProvider={buildAuthProvider()}
    theme={epsyTheme}
    i18nProvider={defaultI18nProvider}
    store={store}
  >
    <IdentityProvider />
    <AdminUI
      ready={Loading}
      title={packageJson.name}
      requireAuth={false}
      disableTelemetry
      loginPage={<LoginRedirect />}
    >
      <Resource
        options={{ label: "Organizations" }}
        name="organization"
        list={OrganizationList}
        create={OrganizationCreate}
        show={OrganizationShow}
        edit={OrganizationEdit}
        icon={OrganizationIcon}
      />
      <Resource options={{ label: "Hcps" }} name="hcp" edit={HcpEdit} />
      <Resource options={{ label: "Hcps" }} name="invitation" create={HcpCreate} />
      <Resource options={{ label: "Tablets" }} name="tablet" create={TabletCreate} edit={TabletEdit} />
      <CustomRoutes noLayout>
        <Route path="/logout" element={<Logout />} />
      </CustomRoutes>
    </AdminUI>
  </AdminContext>
)
