/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:d2ba87ca-218a-47ba-87bc-ce30cfb9a64a",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_nm8YT267M",
  aws_user_pools_web_client_id: "623qi2vva5jin4hlplqc6q6k1v",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint: "https://dkmdpv3s5belnpjv5pf2qsdhii.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_storage_bucket: "hcp-data-portal-app.us-east-1.epsy.app",
  oauth: {
    domain: "auth-admin.portal-app.us-east-1.prod.neuromodulation.cloud",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://admin.portal-app.us-east-1.prod.neuromodulation.cloud/auth-callback",
    redirectSignOut: "https://admin.portal-app.us-east-1.prod.neuromodulation.cloud/logout",
    responseType: "code"
  }
}

export default awsmobile
