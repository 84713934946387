import Chip from "@mui/material/Chip"
import get from "lodash/get"
import { useRecordContext } from "ra-core"
import { memo } from "react"
import { PublicFieldProps } from "react-admin"
import styled from "styled-components"

type CustomChipFieldProps = PublicFieldProps & {
  transformValue?: (value: any) => string
}

export const CustomChipField = memo((props: CustomChipFieldProps) => {
  const { source, transformValue } = props
  const record = useRecordContext(props)
  const value = source ? get(record, source) : record
  return <StyledChip label={transformValue ? transformValue(value) : value} />
})

CustomChipField.displayName = "CustomChipField"

const StyledChip = styled(Chip)`
  margin: 4px;
  cursor: inherit;
`
