import { Storage } from "aws-amplify"
import { useEffect, useState } from "react"

export const useS3Image = (key: string) => {
  const [image, setImage] = useState<string>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getS3Image = async () => {
      if (!key) return setError("Missing key")

      try {
        setLoading(true)
        const image = await Storage.get(key)
        setImage(image)
      } catch {
        setError(`Couldn't fetch image with key: ${key}`)
      }
      setLoading(false)
    }

    getS3Image()
  }, [key])

  return { image, loading, error }
}
