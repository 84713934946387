import { Box, CircularProgress, Typography } from "@mui/material"
import { useRecordContext } from "ra-core"
import { memo } from "react"
import { FieldProps, sanitizeFieldRestProps } from "react-admin"
import styled from "styled-components"
import { useS3Image } from "../hooks/useS3Image"
import defaultOrgLogoSrc from "../images/default-org-logo.png"

interface IconTextFieldProps extends FieldProps {
  iconSource?: string
}

export const IconTextField = memo((props: IconTextFieldProps) => {
  const { source, iconSource, emptyText, ...rest } = props
  const record = useRecordContext(props)
  const value = source ? record[source] || emptyText : emptyText
  const icon = iconSource ? record[iconSource] : null
  const { image, error, loading } = useS3Image(icon)
  if (loading) return <CircularProgress color="secondary" size="24px" />

  const src = !error && image ? image : defaultOrgLogoSrc
  return (
    <Box display="inline-flex" alignItems="center">
      <Img
        src={src}
        alt={`${value ? `${value}'s ` : undefined}Organization Logo`}
        onError={(error) => ((error.target as HTMLImageElement).src = defaultOrgLogoSrc)}
      />
      <Typography {...sanitizeFieldRestProps(rest)}>{value}</Typography>
    </Box>
  )
})

IconTextField.displayName = "IconTextField"

const Img = styled.img`
  width: 40px;
  margin-right: 16px;
  margin-block: 4px;
`
