/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHcp = /* GraphQL */ `
  query GetHcp($memberId: String!) {
    getHcp(memberId: $memberId) {
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      memberId
      organizationId
      user {
        email
        id
        invitationStatus
        name
        npiNumber
        phoneNumber
        role
      }
      userId
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($organizationId: String!) {
    getOrganization(organizationId: $organizationId) {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      organizationTypes
      phoneNumbers
      state
      territory
      zipCode
    }
  }
`;
export const getTabletByIdAndOrganizationId = /* GraphQL */ `
  query GetTabletByIdAndOrganizationId($id: String!, $organizationId: String!) {
    getTabletByIdAndOrganizationId(id: $id, organizationId: $organizationId) {
      createdAt
      id
      lastInterrogationDate
      nickname
      organizationId
      serialNumber
      totalPatients
      totalReadings
      updatedAt
    }
  }
`;
export const listHcp = /* GraphQL */ `
  query ListHcp($limit: Int, $nextToken: String, $organizationId: String!) {
    listHcp(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          invitationStatus
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      nextToken
    }
  }
`;
export const listInvitationByOrganizationId = /* GraphQL */ `
  query ListInvitationByOrganizationId(
    $limit: Int
    $nextToken: String
    $organizationId: String!
  ) {
    listInvitationByOrganizationId(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        conversions
        createdAt
        expiresAt
        invitationId
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOrganization = /* GraphQL */ `
  query ListOrganization($limit: Int, $nextToken: String) {
    listOrganization(limit: $limit, nextToken: $nextToken) {
      items {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        organizationTypes
        phoneNumbers
        state
        territory
        zipCode
      }
      nextToken
    }
  }
`;
export const listPatientByOrganizationId = /* GraphQL */ `
  query ListPatientByOrganizationId(
    $limit: Int
    $nextToken: String
    $organizationId: String!
  ) {
    listPatientByOrganizationId(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        user {
          acceptedAt
          analyticsId
          appsflyerId
          birthdate
          createdAt
          dob
          email
          emailVerified
          gender
          id
          includeInAnalytics
          isMinor
          isOnboard
          lastSentAt
          legacyId
          locale
          marketingOptIn
          name
          notificationsSound
          overlayIsFirstEventOverlayViewed
          overlayIsFirstMedOverlayViewed
          pastMedicationsCount
          phoneNumber
          platform
          profileCompleteDismissed
          profileShareCodeRequested
          roles
          seizureAware
          seizureConvulsive
          seizureFrequency
          synchronizedAt
          tasksAddMeds
          tasksContactCaseManager
          tasksHealthData
          tasksLogFirstEvent
          tasksLogFirstMed
          tasksMedsTried
          tasksReadDreArticle
          tasksVisitVnsWebsite
          updatedAt
          watchRecordingEnabled
          yearsSinceDiagnosis
          zoneinfo
        }
        userId
      }
      nextToken
    }
  }
`;
export const listPatientProfileByOrganizationId = /* GraphQL */ `
  query ListPatientProfileByOrganizationId(
    $limit: Int
    $nextToken: String
    $organizationId: String!
  ) {
    listPatientProfileByOrganizationId(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        IPGs {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        assignedHcps {
          invitation {
            conversions
            createdAt
            expiresAt
            invitationId
            organizationId
            recipientEmail
            recipientName
            recipientPhoneCountryCode
            recipientPhoneNumber
            redemptionLimit
            role
            senderUserId
            sentAt
            token
            type
            updatedAt
          }
          invitationId
          memberId
          organizationId
          user {
            email
            id
            invitationStatus
            name
            npiNumber
            phoneNumber
            role
          }
          userId
        }
        birthDate
        currentIPG {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        deathDate
        disDate
        eosCalculation {
          calculationDate
          expectedLifeRemaining
          maximumLifeRemaining
          minimalLifeRemaining
        }
        expDate
        gender
        id
        indication
        lastReading {
          autoStimulationBlackoutTime
          autoStimulationOn
          autoStimulationThreshold
          autoStimulationTimePercent
          beatsPerMin
          chargePerDay
          dutyCycle
          fetDate
          heartbeatDetection
          id
          importDate
          interrogationDate
          ipg {
            implantingDate
            implantingHospitalSortName
            model
            replacementReason
            returnDate
            serial
            softwareVersion
          }
          isEOS
          isNEOS
          leadImpedanceStatus
          leadImpedanceValue
          magnetStimulationTime
          msid
          nightMode
          nightSettings {
            autoStimulationOnTime
            autoStimulationOutputCurrent
            autoStimulationPulseWidth
            endTime
            magnetOutputCurrent
            magnetPulseWidth
            magnetSignalOnTime
            normalFrequency
            normalOutputCurrent
            normalPulseWidth
            normalSignalOffTime
            normalSignalOnTime
            startTime
          }
          normalSettings {
            autoStimulationOnTime
            autoStimulationOutputCurrent
            autoStimulationPulseWidth
            endTime
            magnetOutputCurrent
            magnetPulseWidth
            magnetSignalOnTime
            normalFrequency
            normalOutputCurrent
            normalPulseWidth
            normalSignalOffTime
            normalSignalOnTime
            startTime
          }
          normalStimulationTime
          offTime
          onTime
          organizationId
          programmingMode
          readingMode
          scheduledPrograming
          seizureDetection
          sessionId
          tablet {
            serial
            softwareVersion
          }
          therapyProtocolType
          time
          totalOnTimeHours
          totalOnTimeMinutes
          uniqueOfficeVisit
          wand {
            serial
            softwareVersion
          }
        }
        managingPhysician {
          NPI
          name
        }
        msid
        name
        part
        patientId
        referringPhysician {
          NPI
          name
        }
        surgeon {
          NPI
          name
        }
      }
      nextToken
    }
  }
`;
export const listTabletByOrganizationId = /* GraphQL */ `
  query ListTabletByOrganizationId(
    $limit: Int
    $nextToken: String
    $organizationId: String!
  ) {
    listTabletByOrganizationId(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        createdAt
        id
        lastInterrogationDate
        nickname
        organizationId
        serialNumber
        totalPatients
        totalReadings
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: UserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        acceptedAt
        analyticsId
        appsflyerId
        birthdate
        createdAt
        dob
        email
        emailVerified
        gender
        id
        includeInAnalytics
        isMinor
        isOnboard
        lastSentAt
        legacyId
        locale
        marketingOptIn
        name
        notificationsSound
        overlayIsFirstEventOverlayViewed
        overlayIsFirstMedOverlayViewed
        pastMedicationsCount
        phoneNumber
        platform
        profileCompleteDismissed
        profileShareCodeRequested
        roles
        seizureAware
        seizureConvulsive
        seizureFrequency
        synchronizedAt
        tasksAddMeds
        tasksContactCaseManager
        tasksHealthData
        tasksLogFirstEvent
        tasksLogFirstMed
        tasksMedsTried
        tasksReadDreArticle
        tasksVisitVnsWebsite
        updatedAt
        watchRecordingEnabled
        yearsSinceDiagnosis
        zoneinfo
      }
      nextToken
    }
  }
`;
export const listVnsDevice = /* GraphQL */ `
  query ListVnsDevice($organizationId: String!) {
    listVnsDevice(organizationId: $organizationId) {
      items {
        blc
        generatorModel
        generatorSerialNumber
        implantedDate
        lastKnownReading
        normalOutputCurrent
        normalPulseWidth
        patientDob
        patientName
        patientProfileId
      }
    }
  }
`;
export const listVnsTablet = /* GraphQL */ `
  query ListVnsTablet($organizationId: String!) {
    listVnsTablet(organizationId: $organizationId) {
      items {
        id
        lastReading
        nickname
        patients
        readings
        serialNumber
      }
    }
  }
`;
