import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceManyField,
  TextField,
  useRecordContext,
  WrapperField
} from "react-admin"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"

export const OrganizationShowTablets = () => (
  <ReferenceManyField reference="tablet" target="organizationId" label={false} perPage={ALL_RECORDS_LIMIT}>
    <Total />
    <Datagrid bulkActionButtons={false}>
      <TextField source="nickname" label="Nickname" />
      <TextField source="serialNumber" label="Tablet Serial Number" />
      <TextField source="totalPatients" label="Total Patients" />
      <TextField source="totalReadings" label="Total Readings" />
      <WrapperField label="Actions">
        <EditTabletButton />
        <DeleteTabletButton />
      </WrapperField>
    </Datagrid>
    <Total />
  </ReferenceManyField>
)

const EditTabletButton = () => {
  const record = useRecordContext()
  return (
    <EditButton
      to={{
        pathname: `/tablet/${record?.id}`,
        search: `organizationId=${record?.organizationId}`
      }}
    />
  )
}

const DeleteTabletButton = () => {
  const { serialNumber, organizationId } = useRecordContext()
  return (
    <DeleteWithConfirmButton
      confirmTitle={`Delete tablet ${serialNumber}? `}
      confirmContent={
        <>
          Are you sure you want to delete tablet with serial number <b>{serialNumber}?</b>
        </>
      }
      mutationOptions={{ meta: { extraFields: { organizationId } } }}
      redirect={false}
    />
  )
}
