export const getChoicesFromEnum = <T extends object>(
  e: T,
  disabledValues: (keyof T)[] = [],
  excludeValues: (keyof T)[] = []
) => {
  const values = Object.values(e)
  const filteredValues = values.filter((value) => !excludeValues.includes(value))
  return filteredValues.map((value) => ({
    id: value,
    name: value,
    disabled: disabledValues.includes(value)
  }))
}
