import { captureException } from "@sentry/react"
import { InvitationType, OrganizationTerritory, OrganizationType } from "../../API"

export const parseOrganizationValue = (organizationType: OrganizationType): "Epsy Hub" | "VNS Portal" => {
  switch (organizationType) {
    case OrganizationType.EPSY_HUB:
      return "Epsy Hub"
    case OrganizationType.VNS_PORTAL:
      return "VNS Portal"
    default:
      captureException(new Error(`Unmapped organizationType: "${organizationType}"`))
      return organizationType
  }
}

export const parseTerritoryValue = (territory: OrganizationTerritory): string => {
  let parts = territory.split("_")
  const territoryCode = parts.pop()
  if (parts.length === 1) return `${territoryCode}-${parts[0]}`
  const keyAccounts = parts.slice(-1)[0] === "KEYACCTS" ? parts.pop() : undefined
  const stateCode = parts.slice(-1)[0].length === 2 ? parts.pop() : undefined
  const territoryName = parts.join(" ")
  return `${territoryCode}-${territoryName}${stateCode ? `, ${stateCode}` : ""}${keyAccounts ? "_KeyAccts" : ""}`
}

export const parseInvitationType = (invitationType: InvitationType): string => {
  switch (invitationType) {
    case InvitationType.hcp_inviting_any_patient_to_org_via_emr:
      return "EMR"
    case InvitationType.hcp_inviting_specific_patient_to_org_via_sms:
      return "SMS"
    case InvitationType.hcp_inviting_specific_staff_to_org_via_email:
    case InvitationType.hcp_inviting_specific_patient_to_org_via_email:
      return "Email"
    case InvitationType.org_inviting_any_patient_to_org_via_digital_media_qr:
    case InvitationType.org_inviting_any_patient_to_org_via_print_media_qr:
      return "QR"
  }
}

export const organizations = Object.values(OrganizationType).map((value) => ({
  id: value,
  name: parseOrganizationValue(value)
}))

export const territories = Object.values(OrganizationTerritory).map((value) => ({
  id: value,
  name: parseTerritoryValue(value)
}))
