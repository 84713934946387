import { CheckSharp, Error as ErrorIcon, ForwardToInbox, MailOutline } from "@mui/icons-material"
import { Icon, Typography } from "@mui/material"
import { captureException } from "@sentry/react"
import { useState } from "react"
import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  EmailField,
  FunctionField,
  ReferenceManyField,
  TextField,
  useDelete,
  useNotify,
  useRecordContext
} from "react-admin"
import { Hcp } from "../../../API"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"
import { resendInvitationMutation } from "../../../data/appsync"

export const OrganizationShowHcps = () => (
  <ReferenceManyField reference="hcp" target="organizationId" label={false} perPage={ALL_RECORDS_LIMIT}>
    <Total />
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        label="Name"
        render={(record: Hcp) => {
          const name = record.user?.name ?? record.invitation?.recipientName
          return <TextField record={{ name }} source="name" label="Name" emptyText="- not set -" />
        }}
      />
      <FunctionField
        label="Email"
        render={(record: Hcp) => {
          const email = record.user?.email ?? record.invitation?.recipientEmail
          return <EmailField record={{ email }} source="email" label="Email" emptyText="- not set -" />
        }}
      />
      <FunctionField
        label="User role"
        render={(record: Hcp) => {
          const role = record.user?.role ?? record.invitation?.role
          return <TextField record={{ role }} source="role" emptyText="- not set -" />
        }}
      />
      <FunctionField
        label="Date invited"
        render={({ user, invitation }: Hcp) => {
          if (user) {
            return (
              <Typography component="span" variant="body2">
                <CheckSharp color="success" /> Accepted
              </Typography>
            )
          }

          if (invitation && invitation.createdAt) {
            const date = new Date(invitation.createdAt)
            const dateStr = date.toLocaleDateString()
            return (
              <Typography component="span" variant="body2">
                <MailOutline color="secondary" /> {dateStr}
              </Typography>
            )
          }
          return (
            <>
              <Icon /> - not set -
            </>
          )
        }}
      />
      <FunctionField
        label="Actions"
        render={(record: Hcp) => {
          if (record.user)
            return (
              <>
                <EditHcpButton />
                <DeleteWithConfirmButton
                  mutationOptions={{ meta: { primaryKey: "memberId" } }}
                  redirect={false}
                  confirmTitle={`Delete HCP: ${record.user.name}? `}
                  confirmContent={
                    <>
                      Are you sure you want to delete HCP: <b>{record.user.name}?</b>
                    </>
                  }
                />
              </>
            )
          if (record.invitation)
            return (
              <>
                <ResendInvitationButton />
                <DeleteInvitedButton />
              </>
            )
          captureException(new Error("listHcp has returned a record with no `user` object or no `invitation` object"), {
            extra: { hcp: record }
          })
          return <ErrorIcon color="error" />
        }}
      />
    </Datagrid>
    <Total />
  </ReferenceManyField>
)

const DeleteInvitedButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const [deleteOne, { isLoading, error }] = useDelete()

  const handleClick = () => {
    deleteOne("invitation", {
      id: record.invitationId || record.invitation.invitationId,
      meta: { primaryKey: "invitationId" }
    })
  }

  const recipientName = record.invitation?.recipientName
  if (error) {
    const message = `Failed to delete invitation
        ${recipientName ? ` to ${recipientName}` : ""}        
      `
    notify(message, { type: "error" })
  }
  return (
    <DeleteWithConfirmButton
      disabled={isLoading}
      onClick={handleClick}
      mutationOptions={{ meta: { primaryKey: "memberId" } }}
      redirect={false}
      confirmTitle={`Delete HCP Invitation for: ${recipientName}? `}
      confirmContent={
        <>
          Are you sure you want to delete invitation for HCP <b>{recipientName}?</b>
        </>
      }
    />
  )
}

const ResendInvitationButton = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    invitationId,
    invitation: { recipientName }
  } = useRecordContext()
  const notify = useNotify()

  const handleClick = async () => {
    try {
      setIsLoading(true)
      await resendInvitationMutation({ invitationId })
      const message = `Successfully resent invitation ${recipientName ? ` to ${recipientName}` : ""}`
      notify(message, { type: "success" })
      setIsLoading(false)
    } catch (error) {
      const message = `Failed to resend invitation${recipientName ? ` to ${recipientName}` : ""}`
      notify(message, { type: "error" })
      setIsLoading(false)
    }
  }

  return (
    <Button
      color="secondary"
      startIcon={<ForwardToInbox />}
      disabled={isLoading}
      label="Resend Invitation"
      onClick={handleClick}
    />
  )
}

const EditHcpButton = () => {
  const record = useRecordContext()
  return (
    <EditButton
      to={{
        pathname: `/hcp/${record?.memberId}`,
        search: `organizationId=${record?.organizationId}`
      }}
    />
  )
}
