import { Grid } from "@mui/material"
import {
  Create,
  CreateProps,
  email,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect
} from "react-admin"
import { useSearchParams } from "react-router-dom"
import { CreateInvitationMutationVariables, HcpRole, InvitationInput, InvitationType, Theme } from "../../API"
import { useFlags } from "../../hooks/useFlags"
import { getChoicesFromEnum } from "../../utils/get-choices-from-enum"
import { deprecatedHcpRoles } from "./common"

type FormProps = Pick<InvitationInput, "recipientEmail" | "recipientName" | "theme">

export const HcpCreate = (props: CreateProps) => {
  const { hcpInvitationRole } = useFlags()
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId") || undefined
  const redirect = useRedirect()

  if (!organizationId) {
    redirect("/organization")
    return null
  }

  const transform = (props: FormProps): CreateInvitationMutationVariables => ({
    input: { ...props, organizationId, type: InvitationType.hcp_inviting_specific_staff_to_org_via_email }
  })

  return (
    <Create {...props} redirect={() => `organization/${organizationId}/show`} title="Create HCP" transform={transform}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput source="recipientName" label="Full name" fullWidth autoFocus required />
            <TextInput
              source="recipientEmail"
              label="Email address"
              fullWidth
              required
              validate={email("Please enter a valid email")}
            />
            <SelectInput
              sx={hcpInvitationRole ? { display: "none" } : undefined}
              source="role"
              label="Role"
              choices={getChoicesFromEnum(HcpRole, [], deprecatedHcpRoles)}
              defaultValue={hcpInvitationRole || HcpRole.Manager}
              disabled={!!hcpInvitationRole}
              required
            />
            <SelectInput source="theme" label="Platform" choices={getChoicesFromEnum(Theme)} required />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
