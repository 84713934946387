import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api"
import { captureException } from "@sentry/react"
import get from "lodash/get"
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "ra-core"
import { Filter } from "./Filter"
import { Pagination } from "./Pagination"

export interface Operations {
  queries: Record<string, string>
  mutations: Record<string, string>
}

interface queryNameManyMap {
  operation?: Operation
  resource?: string
  target?: string
  query: string
}

export interface DataProviderOptions {
  authMode?: GRAPHQL_AUTH_MODE
  queryNameManyMaps?: queryNameManyMap[]
}

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
}

type SortComparatorFn = (record: any) => string | number | undefined

export type Operation = "list" | "get" | "create" | "update" | "delete"

export class DataProvider {
  queries: Record<string, string>
  mutations: Record<string, string>

  authMode: GRAPHQL_AUTH_MODE
  constructor(operations: Operations, private options?: DataProviderOptions) {
    this.queries = operations.queries
    this.mutations = operations.mutations
    this.authMode = options?.authMode || defaultOptions.authMode
  }

  private sortData = (data: any, params: GetListParams, customSortFields?: Record<string, SortComparatorFn>) => {
    return data.sort((a: any, b: any) => {
      const field = params.sort.field
      let valA: string | number | undefined = get(a, field) ?? undefined
      let valB: string | number | undefined = get(b, field) ?? undefined

      /**
       * NOTE: Custom `sortSortFields` are a list of fields that should be parsed or
       * formatted prior to being sorted - this is useful for handling displayed fields
       * that are calculated which may span multiple raw data fields. Also where parsed
       * or formatted values displayed will not match the sort results sort order of the
       * underlying dataset.
       */
      const customSortComparatorFn = customSortFields?.[field]
      if (customSortComparatorFn) {
        valA = customSortComparatorFn(a)
        valB = customSortComparatorFn(b)
      }

      if (params.sort.order === "DESC") {
        if (!valA) return 1
        if (!valB) return -1
        return valB.toString().localeCompare(valA.toString(), undefined, { numeric: true })
      }

      if (!valB) return 1
      if (!valA) return -1
      return valA.toString().localeCompare(valB.toString(), undefined, { numeric: true })
    })
  }

  getList = async (resource: string, params: GetListParams): Promise<GetListResult> => {
    const primaryKey = params.meta?.primaryKey ?? "id"
    const queryName = Filter.getQueryName(this.queries, params.filter) ?? this.getQueryName("list", resource)
    const queryVariables = Filter.getQueryVariables(params.filter) ?? {}
    const query = this.getQuery(queryName)

    const { page, perPage } = params.pagination
    const querySignature = JSON.stringify({ queryName, queryVariables, perPage })
    const nextToken = Pagination.getNextToken(querySignature, page)

    // requested page is out of range
    if (typeof nextToken === "undefined") return { data: [], total: 0 }

    if (params.sort.field === queryName) {
      queryVariables["sortDirection"] = params.sort.order
    }

    const queryData = (await this.graphql(query, { ...queryVariables, limit: perPage, nextToken }))[queryName]
    Pagination.saveNextToken(queryData.nextToken, querySignature, page)

    let data = queryData.items.map((item: any) => {
      const { [primaryKey]: id, ...rest } = item
      return { id, ...rest }
    })

    const clientSideFilter = !!params.meta?.clientSideFilters?.find((filed: string) => params.filter[filed])

    if (clientSideFilter) {
      data = data.filter((item: { [key: string]: string | string[] }) => {
        return Object.entries<string | object>(params.filter)
          .map(([key, filterValue]) => {
            const dataValue = item[key]

            if (dataValue instanceof Array) {
              if (Array.isArray(filterValue)) return filterValue.every((value) => dataValue.includes(value))
              if (typeof filterValue === "string") return dataValue.includes(filterValue)
            }

            if (typeof dataValue == "string") {
              if (Array.isArray(filterValue)) return filterValue.some((value) => dataValue.includes(value))
              if (typeof filterValue === "string") return dataValue.toLowerCase().includes(filterValue.toLowerCase())
            }

            return false
          })
          .every((value) => value === true)
      })
    }
    const customSortField = params.meta?.customSortField
    if (customSortField) {
      try {
        data = this.sortData(data, params, customSortField)
      } catch (error) {
        captureException(error)
      }
    }
    return {
      data,
      total: data ? data.length : 0
    }
  }

  getOne = async (
    resource: string,
    params: Omit<GetOneParams, "meta"> & { meta?: { primaryKey: string; extraFields: any } }
  ): Promise<GetOneResult> => {
    const queryName = this.getQueryName("get", resource)
    const query = this.getQuery(queryName)

    const primaryKey = params.meta?.primaryKey ?? "id"
    const extraFields = params.meta?.extraFields ?? {}
    const variables = { [primaryKey]: params.id, ...extraFields }

    const res = await this.graphql(query, variables)
    const data = res[queryName]

    if (!data) {
      const error = new HttpError("Not found", 404)
      captureException(error)
      throw error
    }

    const { [primaryKey]: id, ...rest } = data
    return { data: { id, ...rest } }
  }

  getMany = async (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    const error = new Error("getMany not implemented")
    captureException(error)
    throw error
  }

  getManyReference = async (resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult> => {
    /**
     * // TODO: Check if this fix is solved completely as its potentially a partial fix.
     *
     * This is a workaround for a bug which causes the `getManyReference` to persists the `filter`
     * elements from previous requests and returning an incorrect value from `Filter.getQueryName`.
     *
     * This fix aims to ignore the `filter` element from the `params` and instead build the filter
     * from scratch on each call.
     *
     * This may affect advanced filtering operations or pagination and therefore should be monitored and
     * fixed if required else this TODO and the respective ticket removed.
     *
     * @see {@link https://linear.app/epsy/issue/FE-327/possible-issue-with-getmanyreference}
     */
    const filter: any = {}
    const { id, pagination, sort, target } = params
    const splitTarget = target.split(".")
    /**
     * If the target is a nested field, we need to build the filter
     * in the format `queryName.resourceId`.
     */
    if (splitTarget.length === 2) {
      if (!filter[splitTarget[0]]) {
        filter[splitTarget[0]] = {}
      }
      filter[splitTarget[0]][splitTarget[1]] = id
    } else {
      const queryName = this.getQueryNameMany("list", resource, target)
      if (!filter[queryName]) {
        filter[queryName] = {}
      }
      filter[queryName][target] = id
    }
    return this.getList(resource, { pagination, sort, filter })
  }

  create = async (resource: string, params: CreateParams): Promise<CreateResult> => {
    const queryName = this.getQueryName("create", resource)
    const query = this.getQuery(queryName)

    const primaryKey = params.meta?.primaryKey ?? "id"
    const extraFields = params.meta?.extraFields ?? {}
    const variables = { [primaryKey]: params.data.id, ...params.data, ...extraFields }

    const res = await this.graphql(query, variables)
    const data = res[queryName]

    if (!data) {
      const error = new HttpError("Not found", 404)
      captureException(error)
      throw error
    }

    const { [primaryKey]: id, ...rest } = data
    return { data: { id, ...rest } }
  }

  update = async (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    const queryName = params.meta?.mutationName ?? this.getQueryName("update", resource)
    const query = this.getQuery(queryName)

    const primaryKey = params.meta?.primaryKey ?? "id"
    const extraFields = params.meta?.extraFields ?? {}
    const variables = { [primaryKey]: params.data.id, ...params.data, ...extraFields }

    const res = await this.graphql(query, variables)
    const data = res[queryName]

    if (!data) {
      const error = new HttpError("Not found", 404)
      captureException(error)
      throw error
    }

    const { [primaryKey]: id, ...rest } = data
    return { data: { id, ...rest } }
  }

  updateMany = async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    const error = new Error("updateMany not implemented")
    captureException(error)
    throw error
  }

  delete = async (resource: string, params: DeleteParams): Promise<DeleteResult> => {
    const queryName = params.meta?.mutationName ?? this.getQueryName("delete", resource)
    const query = this.getQuery(queryName)

    const primaryKey = params.meta?.primaryKey ?? "id"
    const extraFields = params.meta?.extraFields ?? {}
    const variables = { [primaryKey]: params.id, ...extraFields }

    await this.graphql(query, variables)

    return { data: { id: params.id } }
  }

  deleteMany = async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    const error = new Error("deleteMany not implemented")
    captureException(error)
    throw error
  }

  getQuery(queryName: string): string {
    const query = this.queries[queryName]
    const mutation = this.mutations[queryName]

    if (query) return query
    if (mutation) return mutation

    const error = new Error(`Data provider error: query/mutation "${queryName}" not found`)
    captureException(error)

    throw error
  }

  getQueryName(operation: Operation, resource: string): string {
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`
  }

  getQueryNameMany(operation: Operation, resource: string, target: string): string {
    const queryName = this.getQueryName(operation, resource)
    const mappedResult = this.options?.queryNameManyMaps?.find((queryNameManyMap) => {
      return (
        (queryNameManyMap.resource ?? resource) === resource &&
        (queryNameManyMap.target ?? target) === target &&
        (queryNameManyMap.operation ?? operation) === operation
      )
    })
    return mappedResult?.query || `${queryName}By${target.charAt(0).toUpperCase() + target.slice(1)}`
  }

  async graphql(query: string, variables: Record<string, unknown>): Promise<any> {
    const queryResult = (await API.graphql({
      query,
      variables,
      authMode: this.authMode
    })) as GraphQLResult
    if (queryResult.errors) {
      const error = new Error(`Data provider error: ${JSON.stringify(queryResult.errors)}.`)
      captureException(error)
      throw error
    }
    if (!queryResult.data) {
      const error = new Error(`Data provider error: no data returned.`)
      captureException(error)
      throw error
    }
    return queryResult.data
  }
}
