import { API } from "aws-amplify"
import { DeleteTabletMutationVariables, ResendInvitationMutationVariables } from "../API"
import { deleteTablet, resendInvitation } from "../graphql/mutations"

export const resendInvitationMutation = async (input: ResendInvitationMutationVariables) => {
  return await API.graphql({
    query: resendInvitation,
    variables: input,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
}
export const deleteTabletMutation = async (input: DeleteTabletMutationVariables) => {
  return await API.graphql({
    query: deleteTablet,
    variables: input,
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
}
