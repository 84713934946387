import { ContentCopy } from "@mui/icons-material"
import { Button, useNotify } from "react-admin"

interface CopyToClipboardButtonProps {
  label: string
  title: string
  copyConent: string
}

export const CopyToClipboardButton = ({ label, title, copyConent }: CopyToClipboardButtonProps) => {
  const notify = useNotify()
  return (
    <Button
      onClick={async () => {
        await navigator.clipboard.writeText(copyConent)
        notify(
          `Copied ${label}: 
        <b>"${copyConent}"<b/>
         to clipboard`,
          { type: "success" }
        )
      }}
      startIcon={<ContentCopy />}
      label={label}
      title={title}
    />
  )
}
