import { Amplify } from "aws-amplify"
import { BrowserRouter, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { Admin } from "./Admin"
import { MaintenanceBoundery } from "./components/MaintenanceBoundery"
import Version from "./components/Version"
import { useFlags } from "./hooks/useFlags"
import { environment } from "./services/environment"
import { sentry } from "./services/sentry"
import { epsyTheme } from "./theme"

const awsExports = require(`./aws-exports.${environment.env}`).default
if (environment.isLocalEnv) {
  awsExports.oauth.redirectSignIn = "http://localhost:3000/auth-callback"
  awsExports.oauth.redirectSignOut = "http://localhost:3000/logout"
}
Amplify.configure({
  ...awsExports,
  Storage: {
    AWSS3: {
      bucket: awsExports.aws_storage_bucket,
      region: awsExports.aws_project_region
    }
  }
})

const App = () => {
  const { versionInformation } = useFlags()
  return (
    <ThemeProvider theme={epsyTheme}>
      {versionInformation && <Version />}
      <MaintenanceBoundery>
        <BrowserRouter>
          <sentry.Routes>
            {/* Nesting a ReactAdmin into a child Route was required for Sentry's ReactRouter integration (see:
              https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api) 
              with ReactAdmin, be because ReactAdmin hides the implementation of its own ReactRouter. Using ReactAdmn inside a child route (see:
              https://marmelab.com/react-admin/Routing.html#using-react-admin-inside-a-route) solved this issue.
           */}
            <Route path="/*" element={<Admin />} />
          </sentry.Routes>
        </BrowserRouter>
      </MaintenanceBoundery>
    </ThemeProvider>
  )
}

export default App
