import { useFlags } from "launchdarkly-react-client-sdk"
import { ReactNode } from "react"
import { Loading, usePrevious } from "react-admin"
import { Maintenance } from "../views/maintanance/Maintenance"

type MaintenanceBounderyProps = {
  children: ReactNode
}

export const MaintenanceBoundery = ({ children }: MaintenanceBounderyProps) => {
  const { maintenanceMode } = useFlags()
  /**
   * If the application is in maintenance mode, we want to reload the application once the maintenance mode/flag is turned off.
   * The reason for checking the previous maintenance mode is to prevent an infinite loop of reloading the application. If the
   * previous maintenance mode is true and the current maintenance mode is false, we know that the application was in maintenance
   * mode and has just been turned off. We can then reload the application.
   */
  const prevMaintenanceMode = usePrevious(maintenanceMode)
  if (prevMaintenanceMode === true && maintenanceMode === false) {
    window.location.reload()
    return (
      <Loading loadingPrimary="Maintanance complete!" loadingSecondary="Fetching updated Epsy Hub Admin application" />
    )
  }
  return maintenanceMode ? <Maintenance /> : <>{children}</>
}
