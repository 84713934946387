import { Toolbar } from "@mui/material"
import { useListPaginationContext } from "react-admin"
import styled from "styled-components"

export const Total = () => {
  const { total } = useListPaginationContext()
  return (
    <CustomToolbar variant="dense">
      Total records:&nbsp;<b>{total ?? "-"}</b>
    </CustomToolbar>
  )
}

const CustomToolbar = styled(Toolbar)`
  align-items: end;
  justify-content: end;
`
