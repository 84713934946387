import { CheckSharp, MailOutline } from "@mui/icons-material"
import { Icon, Typography } from "@mui/material"
import { Datagrid, FunctionField, ReferenceManyField, TextField, WrapperField } from "react-admin"
import { Patient } from "../../../API"
import { CopyToClipboardButton } from "../../../components/CopyToClipboardButton"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"

type PatientRecord = Omit<Patient, "memeberId"> & { id: string }

export const OrganizationShowPatients = () => (
  <ReferenceManyField
    reference="patientByOrganizationId"
    target="organizationId"
    label={false}
    perPage={ALL_RECORDS_LIMIT}
  >
    <Total />
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        label="Name"
        render={({ user, invitation }: PatientRecord) => {
          const email = user?.name || invitation?.recipientName
          return <TextField record={{ email }} source="email" emptyText="- not set -" />
        }}
      />
      <FunctionField
        label="Email"
        render={({ user, invitation }: PatientRecord) => {
          const email = user?.email || invitation?.recipientEmail
          return <TextField record={{ email }} source="email" emptyText="- not set -" />
        }}
      />
      <FunctionField
        label="Date invited"
        render={({ user, invitation }: PatientRecord) => {
          if (user) {
            return (
              <Typography component="span" variant="body2">
                <CheckSharp color="success" /> Accepted
              </Typography>
            )
          }

          if (invitation && invitation.createdAt) {
            const date = new Date(invitation.createdAt)
            const dateStr = date.toLocaleDateString()
            return (
              <Typography component="span" variant="body2">
                <MailOutline color="secondary" /> {dateStr}
              </Typography>
            )
          }
          return (
            <>
              <Icon /> - not set -
            </>
          )
        }}
      />
      <FunctionField
        label="Actions"
        render={({ id, userId }: PatientRecord) => {
          return (
            <WrapperField label="Actions">
              <CopyToClipboardButton label="Long ID" title="Copy Long ID" copyConent={id} />
              {userId && <CopyToClipboardButton label="Short ID" title="Copy Short ID" copyConent={userId} />}
            </WrapperField>
          )
        }}
      />
    </Datagrid>
    <Total />
  </ReferenceManyField>
)
