import { ArrowBackSharp } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"
import { TopToolbar, useRecordContext, useResourceContext } from "react-admin"
import { useNavigate } from "react-router"
import styled from "styled-components"

interface BreadcrumbActionToolbarProps {
  rightView?: ReactNode
  labelSource?: string
}

export const BreadCrumbActionToolbar = ({ rightView, labelSource }: BreadcrumbActionToolbarProps) => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const navigate = useNavigate()

  if (!record) return null
  return (
    <TopToolbar>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Breadcrumb onClick={() => navigate(`/${resource}`)}>
          <Typography variant="h5">
            <ArrowBackSharp /> {labelSource && record[labelSource]}
          </Typography>
        </Breadcrumb>
        {rightView}
      </Box>
    </TopToolbar>
  )
}

const Breadcrumb = styled.a`
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
`
