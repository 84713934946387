import { Auth } from "aws-amplify"
// eslint-disable-next-line no-restricted-imports
import { buildAuthProvider as raBuildAuthProvider } from "react-admin-amplify"
import { AuthProviderOptions } from "react-admin-amplify/build/providers/AuthProvider"
import { environment } from "../services/environment"

export const buildAuthProvider = (options?: AuthProviderOptions) => {
  return {
    ...raBuildAuthProvider(options),
    getIdentity: async () => {
      const {
        attributes: { email, email_verified: emailVerified },
        username: id
      } = await Auth.currentAuthenticatedUser()
      return { id, email, emailVerified }
    },
    ...(environment.isPortal
      ? {
          checkAuth: async () => {
            if (window.location.pathname === "/logout") return Auth.signOut()
            try {
              return await Auth.currentAuthenticatedUser()
            } catch {
              Auth.federatedSignIn({ customProvider: "Okta" })
            }
            return Promise.reject()
          },
          checkError: () => Promise.resolve(),
          getPermissions: () => Promise.resolve(),
          login: () => Promise.resolve(),
          logout: () => Auth.signOut(),
          handleCallback: () => Promise.resolve()
        }
      : {})
  }
}
