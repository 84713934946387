import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import { getFlagsProvider } from "./providers/flagsProvider"
import reportWebVitals from "./reportWebVitals"
import { sentry } from "./services/sentry"

sentry.init()
;(async () => {
  try {
    const FlagsProvider = await getFlagsProvider()
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
    root.render(
      <React.StrictMode>
        <FlagsProvider>
          <App />
        </FlagsProvider>
      </React.StrictMode>
    )
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  } catch (error) {
    console.error(error)
  }
})()
