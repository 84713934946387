import { createTheme } from "@mui/material/styles"
import { defaultTheme } from "react-admin"

export const epsyTheme = createTheme({
  ...defaultTheme,
  ...{
    palette: {
      success: {
        main: "#04c57f"
      },
      background: {
        default: "#fafbfd"
      },
      secondary: {
        light: "#6ec6ff",
        main: "#400198",
        dark: "#bab8bc",
        contrastText: "#fafbfd"
      }
    },
    typography: {
      fontFamily: ["Gilroy", "sans-serif"].join(","),
      button: {
        textTransform: "none"
      }
    },
    sidebar: { width: 220, closedWidth: 80 },
    components: {
      ...defaultTheme.components,
      MuiToolbar: {
        styleOverrides: {
          root: {
            "&.RaAppBar-toolbar": {
              marginBottom: "0px"
            },
            marginBottom: "4px"
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            verticalAlign: "middle"
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            color: "white",
            backgroundColor: "#400198"
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            ".RaSidebar-fixed": {
              backgroundColor: "#400198",
              height: "100vh !important"
            },
            "& .MuiMenuItem-root": {
              height: "56px",
              borderRadius: "27px",
              marginInline: "12px",
              "&, & .MuiSvgIcon-root": {
                color: "white"
              },
              "&.RaMenuItemLink-active, &.RaMenuItemLink-active .MuiSvgIcon-root": {
                color: "#400198",
                backgroundColor: "#DBCDFE"
              }
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&.RaCreateButton-root": {
              height: "56px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingLeft: "19px",
              paddingRight: "24px",
              borderRadius: "27px",
              backgroundColor: "#F20493",
              color: "white",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "20px"
            }
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined" as const,
          margin: "dense" as const,
          size: "small" as const
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: "outlined" as const,
          margin: "dense" as const,
          size: "small" as const
        }
      }
    }
  }
})
