import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
  Replay,
  withSentryReactRouterV6Routing
} from "@sentry/react"
import { useEffect } from "react"
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from "react-router-dom"
import { environment } from "./environment"

export const sentry = {
  init: () =>
    init({
      environment: environment.env,
      release: environment.version,
      dsn: environment.sentryDsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          )
        }),
        new Replay()
      ],
      tracesSampleRate: environment.sentryTracesSampleRate,
      replaysSessionSampleRate: environment.sentryReplaysSessionSampleRate,
      replaysOnErrorSampleRate: environment.sentryReplaysOnErrorSampleRate,
      enabled: !environment.isLocalEnv
    }),
  Routes: withSentryReactRouterV6Routing(Routes)
}
