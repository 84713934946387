import { Box, Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material"
import styled from "styled-components"

export const Maintenance = () => (
  <Container display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw">
    <MessageCard>
      <CardHeader title="Epsy Admin Portal will be back soon!" titleTypographyProps={{ textAlign: "center" }} />
      <CardImage image="/images/maintanance.webp" title="Maintanance mode" />
      <CardContent>
        <Typography variant="h6" textAlign="justify">
          Please hang in there for a few minutes ...
        </Typography>
        <Typography variant="body1" textAlign="justify" color="text.secondary">
          We are currently working on some essential maintanance on the Epsy Admin Panel. We aim to have this done as
          quickly as possible. You can keep this page open and it will automatically refresh when we are done.
        </Typography>
      </CardContent>
    </MessageCard>
  </Container>
)

const MessageCard = styled(Card)`
  padding: 16px;
  justify-content: center;
  align-items: center;
  max-width: 500px;
`

const CardImage = styled(CardMedia)`
  aspect-ratio: 57 / 40;
  text-align: center;
`

const Container = styled(Box)`
  background-color: ${(p) => p.theme.palette.secondary.main};
`
