import { Grid } from "@mui/material"
import {
  Edit,
  EditProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
  useRedirect
} from "react-admin"
import { useSearchParams } from "react-router-dom"
import { Hcp, HcpRole, UpdateHcpUserMutationVariables } from "../../API"
import { useFlags } from "../../hooks/useFlags"
import { getChoicesFromEnum } from "../../utils/get-choices-from-enum"
import { deprecatedHcpRoles } from "./common"

export const HcpEdit = (props: EditProps) => {
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId") || undefined
  const redirect = useRedirect()

  if (!organizationId) {
    redirect("/organization")
    return null
  }

  const transform = ({ user }: Hcp): UpdateHcpUserMutationVariables => {
    if (!user) throw new Error("User not found")
    return {
      id: user.id,
      input: {
        name: user.name,
        npiNumber: user.npiNumber,
        phoneNumber: user.phoneNumber,
        role: user.role
      }
    }
  }

  return (
    <Edit
      {...props}
      queryOptions={{ meta: { primaryKey: "memberId" } }}
      title="Update HCP"
      redirect={() => `organization/${organizationId}/show`}
      transform={transform}
      mutationOptions={{ meta: { mutationName: "updateHcpUser" } }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <EditHcpForm />
      </SimpleForm>
    </Edit>
  )
}

const EditHcpForm = () => {
  const { hcpInvitationRole } = useFlags()
  const record = useRecordContext()
  const validateRole = (role: HcpRole) =>
    deprecatedHcpRoles.includes(role)
      ? `Hcp role "${role}" has been deprecated, please change the role to a currently supported role`
      : undefined
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <TextInput source="user.name" label="Full name" fullWidth required />
        <SelectInput
          source="user.role"
          label="Role"
          choices={getChoicesFromEnum(
            HcpRole,
            deprecatedHcpRoles,
            /**
             * We want to exclude any deprecated HCP roles that the current HCP in not already assigned.
             */
            deprecatedHcpRoles.filter((role) => role !== record.user?.role)
          )}
          defaultValue={hcpInvitationRole || HcpRole.Manager}
          disabled={!!hcpInvitationRole}
          validate={validateRole}
          required
        />
      </Grid>
    </Grid>
  )
}
