import { Add } from "@mui/icons-material"
import { Box } from "@mui/system"
import {
  ArrayField,
  CreateButton,
  Datagrid,
  EditButton,
  FilterButton,
  FilterForm,
  FunctionField,
  List,
  ListProps,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField
} from "react-admin"
import { Organization } from "../../API"
import { CustomChipField } from "../../components/CustomChipField"
import { Total } from "../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../constants/pagination"
import { IconTextField } from "../../fields/IconTextField"
import { useFlags } from "../../hooks/useFlags"
import { organizations, parseOrganizationValue, parseTerritoryValue, territories } from "./helpers"

export const OrganizationList = (props: ListProps) => {
  const { organizationLogo } = useFlags()

  const territoryParser = (record: Organization) =>
    record.territory ? parseTerritoryValue(record.territory) : undefined

  return (
    <List
      {...props}
      pagination={false}
      perPage={ALL_RECORDS_LIMIT}
      actions={<Actions />}
      queryOptions={{
        meta: {
          clientSideFilters: ["name", "territory", "organizationTypes"],
          customSortField: { territory: territoryParser },
          primaryKey: "organizationId"
        }
      }}
    >
      <Total />
      <Datagrid size="small" optimized bulkActionButtons={false} rowClick="show">
        {organizationLogo ? (
          <IconTextField source="name" iconSource={"logo"} emptyText="- not set -" />
        ) : (
          <TextField source="name" emptyText="- not set -" />
        )}
        <FunctionField source="territory" render={territoryParser} emptyText="- not set -" />
        <ArrayField source="organizationTypes" sortable={false} emptyText="- not set -">
          <SingleFieldList>
            <CustomChipField transformValue={parseOrganizationValue} />
          </SingleFieldList>
        </ArrayField>
        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      </Datagrid>
      <Total />
    </List>
  )
}

const Actions = () => {
  const postFilters = [
    <TextInput label="Name" source="name" alwaysOn />,
    <SelectArrayInput source="territory" label="Territory" choices={territories} alwaysOn sx={{ minWidth: "110px" }} />,
    <SelectArrayInput
      source="organizationTypes"
      label="Organization Types"
      choices={organizations}
      alwaysOn
      sx={{ minWidth: "190px" }}
    />
  ]
  return (
    <TopToolbar style={{ width: "100%" }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="row">
          <FilterForm filters={postFilters} />
          <FilterButton filters={postFilters} style={{ alignSelf: "center" }} />
        </Box>
        <CreateButton label="Create Organization" startIcon={<Add />} />
      </Box>
    </TopToolbar>
  )
}
